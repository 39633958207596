import type { GetServerSideProps } from 'next';
import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { getRegionByVoteKey } from '@mentimeter/region';
import type { Store } from '@reduxjs/toolkit';
import { useRouter } from 'next/router';
import { setClientFallbackRegion, voting } from '@mentimeter/http-clients';
import { createStore } from '../redux-store';
import { HeadMeta } from '../features/HeadMeta';
import { Series } from '../features/Series';
import Closed from '../features/Closed';
import type { Translation } from '../languages';
import { requestLanguageFile } from '../languages';
import { identifierClient } from '../helpers/identifier';
import { fetchSeriesSuccess } from '../reducers/seriesSlice';

let serverStore: Store | null = null;

type Props =
  | {
      isClosed: false;
      serializedInitialState: string;
      translations: Translation;
    }
  | {
      isClosed: true;
      serializedInitialState?: undefined;
      translations?: undefined;
    };

const seriesCacheTime = process.env.SERIES_CACHE_TIME;

export default function SeriesPage({
  isClosed = false,
  serializedInitialState = '{}',
  translations,
}: Props) {
  const { query } = useRouter();
  const voteKey = query.voteParams![0]!;

  React.useEffect(() => {
    const region = getRegionByVoteKey(voteKey);
    setClientFallbackRegion('voting', region);
    identifierClient.setRegion(region);
  }, [voteKey]);

  const store = React.useMemo(() => {
    if (typeof window === 'undefined') return serverStore;
    if (isClosed) return null;
    const preloadedState = JSON.parse(serializedInitialState);
    return createStore(preloadedState);
  }, [isClosed, serializedInitialState]);

  if (isClosed) return <Closed />;

  return (
    <ReduxProvider store={store!} key="provider">
      <HeadMeta title="Voting" description="Vote on a Mentimeter question" />
      <Series translations={translations} />
    </ReduxProvider>
  );
}

export const getServerSideProps: GetServerSideProps<
  Props,
  { voteParams: string[] }
> = async (ctx) => {
  //
  const [voteKey] = ctx.query.voteParams ?? [];

  if (!voteKey || !isValidVoteKey(voteKey)) return { notFound: true };
  const region = getRegionByVoteKey(voteKey);
  const { series, error } = await voting({ region })
    .series.getByKey(voteKey)
    .then((response) => ({ series: response.data, error: null }))
    .catch((e) => {
      return e?.response?.status === 403
        ? { series: null, error: 'closed' }
        : { series: null, error: 'not_found' };
    });

  if (error === 'closed') return { props: { isClosed: true } };
  if (error === 'not_found' || !series) return { notFound: true };

  serverStore = createStore({});
  serverStore.dispatch(fetchSeriesSuccess(series));

  ctx.res.setHeader(
    'Cache-Control',
    `public, max-age=${seriesCacheTime}, stale-while-revalidate=${seriesCacheTime}, stale-if-error=86400`,
  );

  const translations = await requestLanguageFile(series.language);

  return {
    props: {
      isClosed: false,
      // Serialize it manually, because the serializer in Next.js cannot handle explicitly undefined values.
      serializedInitialState: JSON.stringify(serverStore.getState()),
      translations,
    },
  };
};

/**
 * Since this endpoint is a catch-all, it started responding to requests for all non-existing assets.
 * Doing a bit of filtering here will reduce error logs and decrease request to core-api.
 *
 * TODO: We should probably prefix the series path with something, i.e. `menti.com/p/2asd72hsd/5`
 */
function isValidVoteKey(voteKey: string) {
  const lowerCaseVoteKey = voteKey.toLowerCase();
  if (lowerCaseVoteKey.includes('icon')) return false;
  if (lowerCaseVoteKey.includes('.png')) return false;
  if (lowerCaseVoteKey.includes('assets')) return false;
  if (lowerCaseVoteKey.includes('robots.txt')) return false;

  // Only allow alpha-numberic vote keys
  return Boolean(lowerCaseVoteKey.match(/^[a-z0-9]+$/));
}
