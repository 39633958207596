import {
  VALID_MODULE_IDS,
  DEPRECATED_MODULE_ID,
} from '@mentimeter/http-clients';
import type { ValidModuleId } from '@mentimeter/http-clients';

export class InvalidModuleIdError extends Error {}

export const getValidModuleIdFromString = (moduleId: string) => {
  const validId = VALID_MODULE_IDS.find((id) => id === moduleId);
  if (validId) {
    return validId as ValidModuleId;
  }
  const deprecatedId = DEPRECATED_MODULE_ID.find((id) => id === moduleId);
  if (deprecatedId) {
    return 'deprecated';
  }

  throw new InvalidModuleIdError(`Unknown module id '${moduleId}'!`);
};

export const VALID_MODULE_IDS_NEW = [
  '100-points',
  'big',
  'bullets',
  'choices',
  'deprecated',
  'document',
  'free-text',
  'google-slides',
  'heading',
  'image',
  'instructions',
  'metadata',
  'miro',
  'number',
  'numerical-question',
  'open',
  'paragraph',
  'pin-on-image',
  'powerpoint',
  'qfa',
  'quiz-choices',
  'quiz-leaderboard',
  'quiz-open',
  'quote',
  'ranking',
  'rating',
  'scales',
  'video',
  'wordcloud',
] as const;

export type ValidModuleIdNew = (typeof VALID_MODULE_IDS_NEW)[number];

const DEPRECATED_MODULE_IDS_NEW = [
  'budget',
  'arrows',
  'brainstorm',
  'breathe',
  'eurovision',
  'drum-roll',
  'code',
  'figma',
  'funnel',
  'loop',
  'spin-the-wheel',
  'this-or-that',
  'timeline',
  'time-keep',
  'trafficlights',
  'truth-or-lie',
  'winner',
  'hub',
  'line-chart',
  'match-the-pairs',
  'nps',
  'slide',
  'the-team',
  'ui-samples',
  'venn',
] as const;

/**
 * Used in experiment for slide removal (SLI_Remove_Slide_Types)
 */
export const getValidModuleIdFromStringNew = (
  moduleId: string,
): ValidModuleIdNew => {
  const validId = VALID_MODULE_IDS_NEW.find((id) => id === moduleId);
  if (validId) {
    return validId;
  }
  const deprecatedId = DEPRECATED_MODULE_IDS_NEW.find((id) => id === moduleId);
  if (deprecatedId) {
    return 'deprecated';
  }

  throw new InvalidModuleIdError(`Unknown module id '${moduleId}'!`);
};
