import type Ably from 'ably';

const ablyKey = globalThis.__mentimeterEnv['NEXT_PUBLIC_ABLY_KEY'] as string;
const ablyRealtimeHost = globalThis.__mentimeterEnv['NEXT_PUBLIC_ABLY_REALTIME_HOST'];
const ablyFallbacks = globalThis.__mentimeterEnv['NEXT_PUBLIC_ABLY_FALLBACKS'];

export const ablyConfig: Ably.ClientOptions = {
  key: ablyKey,
  restHost: ablyRealtimeHost ? ablyRealtimeHost : '',
  realtimeHost: ablyRealtimeHost ? ablyRealtimeHost : '',
  fallbackHosts: ablyFallbacks ? ablyFallbacks.split(',') : [],
};
