import { useTrack } from '../../helpers/tracker';
import { useSplitTrack } from '../../split.io';
import { Footer } from '../../ui';

export const FinishScreenFooter = () => {
  const trackSplit = useSplitTrack();
  const trackVoter = useTrack();

  const registerPoweredByClick = () => {
    trackSplit('End_screen.Link_clicked.Powered_by');
    trackVoter({
      event: 'Clicked footer link',
    });
  };

  return <Footer onPoweredByClick={registerPoweredByClick} />;
};
