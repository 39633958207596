import * as React from 'react';
import type { Question } from '@mentimeter/http-clients';
import { Reactions } from '../reactions';
import { Qfa } from '../qfa/Qfa';
import { Cfa } from '../cfa/Cfa';
import { useAppSelector } from '../../redux-hooks';
import { getIsQfaActive } from '../../selectors';

export const SlideInteractions = ({
  question,
  cfa,
  qfa,
}: {
  question: Question;
  cfa?: React.ReactNode;
  qfa?: React.ReactNode;
}) => {
  const commentsEnabled = useAppSelector(
    (state) => state.series.comments_enabled,
  );
  const isQfaActiveOnAllSlides = useAppSelector(getIsQfaActive);
  const seriesReactions = useAppSelector((state) => state.series.reactions);

  const shouldShowQfaButton = question.type === 'qfa' || isQfaActiveOnAllSlides;
  const reactions = seriesReactions ?? question.reactions;
  const reactionsEnabled = reactions && reactions.length > 0;

  if (!commentsEnabled && !shouldShowQfaButton && !reactionsEnabled)
    return null;

  return (
    <>
      <Reactions question={question} availableReactions={reactions} />

      {commentsEnabled &&
        (cfa ?? <Cfa activePublicKey={question.public_key} />)}
      {shouldShowQfaButton &&
        (qfa ?? (
          <Qfa
            activePublicKey={question.public_key}
            moduleId={question.module_id}
          />
        ))}
    </>
  );
};
