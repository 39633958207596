import { useRouter } from 'next/router';
import React from 'react';
import { useAudiencePaceVoteParams } from './useAudiencePaceVoteParams';

const getQuestionIndexPath = (voteKey: string, questionIndex: number) =>
  `/${voteKey}/${questionIndex}`;

export const useNavigateToNextSlide = () => {
  const { voteKey, questionIndex } = useAudiencePaceVoteParams();
  const { push: routerPush } = useRouter();

  return React.useCallback(() => {
    // Shallow routing means we skip running the getServerSideProps fn for
    // the next route. As we already have the whole presentation state accessible
    // we don't need to refetch it.
    routerPush(getQuestionIndexPath(voteKey, questionIndex + 1), undefined, {
      shallow: true,
    });
  }, [routerPush, questionIndex, voteKey]);
};
