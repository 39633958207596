import { useRouter } from 'next/router';
import { resetIdentifier } from '../../helpers/identifier';

export const useVoteAgain = (voteKey: string) => {
  const router = useRouter();

  return function voteAgain() {
    resetIdentifier();
    if (router.asPath === `/${voteKey}` || router.asPath === `/${voteKey}/0`) {
      router.reload();
    } else {
      router.push(`/${voteKey}`);
    }
  };
};
