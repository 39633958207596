import { Box, Text } from '@mentimeter/ragnar-ui';
import * as React from 'react';
import { SeriesLogo } from '../questions/SeriesLogo';
import { PageLayout } from '../../ui';
import { useTranslate } from '../localization/Translate';

interface IdentificationScreenProps {
  children: React.ReactNode;
}

export const IdentificationScreen = ({
  children,
}: IdentificationScreenProps) => {
  const translate = useTranslate();

  return (
    <PageLayout footer={null}>
      <Box
        flex="1"
        width="100%"
        gap="space2"
        alignItems="stretch"
        m="auto"
        maxWidth={[null, '600px']}
      >
        <SeriesLogo />
        <Text fontSize="175">
          {translate('identified_responses.what_is_your_name')}
        </Text>
        <Text fontSize="112.5">
          {translate('identified_responses.your_name_will_be_shown')}
        </Text>
        {children}
      </Box>
    </PageLayout>
  );
};
