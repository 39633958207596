import dynamic from 'next/dynamic';
import type { ModuleId, ValidModuleId } from '@mentimeter/http-clients';
import { MentiError } from '@mentimeter/errors';
import { getValidModuleIdFromString } from './get-valid-module-id';
import { LoadingPlaceholder } from './LoadingPlaceholder';

const MODULE_IMPORT_MAP: Partial<
  Record<
    ValidModuleId,
    () => Promise<{
      Interactive?: React.ComponentType;
      Content: React.ComponentType;
    }>
  >
> = {
  arrows: () => import('@mentimeter/question-module-arrows/voting'),
  big: () => import('@mentimeter/question-module-big/voting'),
  breathe: () => import('@mentimeter/question-module-breathe/voting'),
  'drum-roll': () => import('@mentimeter/question-module-drum-roll/voting'),
  eurovision: () => import('@mentimeter/question-module-eurovision/voting'),
  loop: () => import('@mentimeter/question-module-loop/voting'),
  miro: () => import('@mentimeter/question-module-miro/voting'),
  'numerical-question': () =>
    import('@mentimeter/question-module-numerical-question/voting'),
  open: () => import('@mentimeter/question-module-open/voting'),
  'quiz-choices': () =>
    import('@mentimeter/question-module-quiz-choices/voting'),
  'quiz-leaderboard': () =>
    import('@mentimeter/question-module-quiz-leaderboard/voting'),
  'quiz-open': () => import('@mentimeter/question-module-quiz-open/voting'),
  ranking: () => import('@mentimeter/question-module-ranking/voting'),
  'spin-the-wheel': () =>
    import('@mentimeter/question-module-spin-the-wheel/voting'),
  'this-or-that': () =>
    import('@mentimeter/question-module-this-or-that/voting'),
  'time-keep': () => import('@mentimeter/question-module-time-keep/voting'),
  timeline: () => import('@mentimeter/question-module-timeline/voting'),
  trafficlights: () =>
    import('@mentimeter/question-module-trafficlights/voting'),
  'truth-or-lie': () =>
    import('@mentimeter/question-module-truth-or-lie/voting'),
  video: () => import('@mentimeter/question-module-video/voting'),
  winner: () => import('@mentimeter/question-module-winner/voting'),
  wordcloud: () => import('@mentimeter/question-module-wordcloud/voting'),
};

const EmptyInteractive = () => null;

export function importVotingDynamically(
  moduleId: ModuleId,
  componentType: 'interactive' | 'content',
) {
  const validModuleId = getValidModuleIdFromString(moduleId);
  // Evaluate only when it gets called
  const importFn = MODULE_IMPORT_MAP[validModuleId];

  return dynamic(
    () => {
      if (!importFn) {
        throw new MentiError(
          `Tried to load voting question module ${moduleId} from importVotingDynamically but has already been moved to the voting app.`,
          { feature: 'voting-slide-type-importer' },
        );
      }
      return importFn()
        .then((c) =>
          componentType === 'interactive'
            ? c.Interactive ?? EmptyInteractive
            : c.Content,
        )
        .catch((error) => {
          throw new Error(
            `Question modules: Failed to load Voting dynamically for moduleId: '${moduleId}'. Error message: ${error}`,
          );
        });
    },
    { loading: LoadingPlaceholder },
  );
}
