import { styled } from '@mentimeter/ragnar-styled';
import { cn } from '@mentimeter/ragnar-tailwind-config';

const ImageStyled = styled('img')(
  'kosmosSpacing',
  'width',
  'height',
  'maxWidth',
  'maxHeight',
);

export type ImageT = React.ComponentProps<typeof ImageStyled>;

export const Image = ({ className, ...props }: ImageT) => {
  const defaultClasses = ['r-img', 'max-w-full', 'max-h-full'];

  return <ImageStyled className={cn(defaultClasses, className)} {...props} />;
};
