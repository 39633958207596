import { createSelector } from '@reduxjs/toolkit';
import memoize from 'lodash/memoize';
import type { DSC } from '@mentimeter/ragnar-dsc';
import { designSystemConfig } from '@mentimeter/ragnar-dsc';
import { createUserTheme } from '@mentimeter/sfinx-themes';
import type { Question } from '@mentimeter/http-clients';
import { themes } from '@mentimeter/ragnar-colors';
import { ALL_QUIZ_QUESTION_TYPES } from '../constants/questionTypes';
import { VOTING_THEME_OVERRIDES } from '../constants/voting-theme-overrides';
import { getOpaqueTheme } from '../helpers/theme-enhancers';
import type { RootState } from '../redux-store';

const matchAnyQuestionKey = (question: Question, key: string) => {
  return question.public_key === key || question.id === key;
};

export const getHasCustomTheme = (state: RootState) =>
  !state.series.theme.is_public;

const getTheme = (state: RootState) => state.series.theme;

export const getDSCWithTheme: (state: RootState) => DSC = createSelector(
  [getTheme],
  (theme) => {
    if (theme.config_id === 'business-light')
      return {
        ...designSystemConfig,
        ...themes.light,
        pictogramColor: undefined,
      };
    if (theme.config_id === 'business-dark')
      return {
        ...designSystemConfig,
        ...themes.dark,
        pictogramColor: undefined,
      };
    const { background_color, text_color, line_color, bar_color } =
      getOpaqueTheme(theme);
    const userTheme = createUserTheme({
      userColors: {
        background_color,
        text_color,
        line_color,
        bar_color,
      },
    });

    return {
      pictogramColor: bar_color[0],
      ...designSystemConfig,
      ...userTheme,
      ...VOTING_THEME_OVERRIDES,
    };
  },
);

const getAllQuestions = (state: RootState) => state.series.questions;

// Memoize filtered questions by using create selector
export const getQuestions = createSelector([getAllQuestions], (questions) =>
  questions.filter((q) => !q.skip_slide),
);

/***************************************************************
 * Quiz
 *
 * Quiz slides means all type of question/slide types related to
 * the quiz, including for example the leaderboard. This could be
 * questions you can answer but also static slide types such
 * as the leaderboard.
 *
 * Quiz questions means all quiz questions, excluding for example
 * the leaderboard. These are only the slides/questions that you
 * can actually answer.
 */

export const getQuizQuestions: (appState: RootState) => Question[] =
  createSelector([getQuestions], (questions) =>
    questions.filter((q) =>
      ALL_QUIZ_QUESTION_TYPES.some((qt) => q.type === qt),
    ),
  );

export const getNumberOfQuizQuestions: (appState: RootState) => number =
  createSelector([getQuizQuestions], (questions) => {
    return questions.length;
  });

export const getQuizQuestionPositionById: (
  appState: RootState,
) => (questionId: string) => number = createSelector(
  [getQuizQuestions],
  (questions) =>
    memoize(
      (questionId: string) =>
        questions.findIndex((q) => matchAnyQuestionKey(q, questionId)) + 1,
    ),
);

/***************************************************************
 * QFA
 */
export const getIsQfaActive = (state: RootState) => state.series.qfa_active;

export const getEmojiFilterEnabled = (state: RootState) => {
  return (
    state.series.profanity_lang &&
    state.series.profanity_lang.includes('ascii_emoji')
  );
};
