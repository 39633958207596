import type { ComponentType } from 'react';
import dynamic from 'next/dynamic';
import type { ModuleId, ValidModuleId } from '@mentimeter/http-clients';
import {
  importVotingDynamically,
  LoadingPlaceholder,
} from '@mentimeter/question-modules-importer';
import { MentiError } from '@mentimeter/errors';
import { getValidModuleIdFromString } from './getValidModuleId';

const MODULE_IMPORT_MAP: Partial<
  Record<
    ValidModuleId,
    () => Promise<{
      Interactive?: ComponentType;
      Content: ComponentType;
    }>
  >
> = {
  '100-points': () => import('./100-points'),
  bullets: () => import('./bullets'),
  choices: () => import('./choices'),
  deprecated: () => import('./deprecated'),
  document: () => import('./document'),
  'free-text': () => import('./free-text'),
  'google-slides': () => import('./google-slides'),
  heading: () => import('./heading'),
  image: () => import('./image'),
  instructions: () => import('./instructions'),
  metadata: () => import('./metadata'),
  number: () => import('./number'),
  paragraph: () => import('./paragraph'),
  'pin-on-image': () => import('./pin-on-image'),
  powerpoint: () => import('./powerpoint'),
  qfa: () => import('./qfa'),
  quote: () => import('./quote'),
  rating: () => import('./rating'),
  scales: () => import('./scales'),
};

const EmptyComponent = () => null;

export const importSlideTypeDynamically = (
  moduleId: ModuleId,
  componentType: 'interactive' | 'content',
) => {
  const validModuleId = getValidModuleIdFromString(moduleId);

  const importFn = MODULE_IMPORT_MAP[validModuleId];
  if (importFn) {
    return dynamic(
      () =>
        importFn()
          .then((c) =>
            componentType === 'interactive'
              ? c.Interactive ?? EmptyComponent
              : c.Content,
          )
          .catch((error) => {
            throw new MentiError(
              `Question modules: Failed to load Voting dynamically for moduleId: '${moduleId}'. Error message: ${error}`,
              { feature: 'voting-slide-type-importer' },
            );
          }),
      { loading: LoadingPlaceholder },
    );
  }

  return importVotingDynamically(moduleId, componentType);
};
