import React from 'react';
import {
  Channels,
  useSubscribe,
  PresentationStateEvents,
  useHistory,
} from '@mentimeter/realtime';
import type Ably from 'ably';
import { MentiError, captureException } from '@mentimeter/errors';
import { usePresentation } from './state';

// Should subscribe only once per device
export function useVotingSetupPresentationState({
  voteKey,
  shouldSubscribe = true,
  restClient,
}: {
  voteKey: string;
  shouldSubscribe: boolean;
  restClient: Ably.Rest;
}) {
  const setIncomingState = usePresentation(
    (state) => state.internalActions.setIncomingState,
  );

  useHistory({
    client: restClient,
    channel: { channel: Channels.PRESENTATION_STATE, value: voteKey },
    callback: React.useCallback(
      (msg) => {
        if (!msg) return;
        if (!msg.data) return;

        try {
          setIncomingState(msg.data, {
            timestamp: msg.timestamp,
            connectionId: msg.connectionId,
            placement: undefined,
          });
        } catch (error) {
          captureException(
            new MentiError(
              "Couldn't update presentation state in response to incoming message from History API. User might be out of sync with presentation.",
              {
                feature: 'presentation-state',
                cause: error,
              },
            ),
          );
        }
      },
      [setIncomingState],
    ),
  });

  useSubscribe(
    {
      channel: Channels.PRESENTATION_STATE,
      value: voteKey,
      shouldSubscribe,
    },
    PresentationStateEvents.PRESENTATION_STATE_SYNC_STATE,
    async (msg) => {
      try {
        setIncomingState(msg.data, {
          timestamp: msg.timestamp,
          connectionId: msg.connectionId,
          placement: undefined,
        });
      } catch (error) {
        captureException(
          new MentiError(
            "Couldn't update presentation state in response to incoming message update. User might be out of sync with presentation.",
            {
              feature: 'presentation-state',
              cause: error,
            },
          ),
        );
      }
    },
  );
}
