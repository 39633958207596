import { useCallback, useEffect } from 'react';
import { useAppSelector } from '../../redux-hooks';
import {
  Footer,
  NavigationButtonExperiment,
  PageLayout,
  ProgressBar,
  SignUpButtonExperiment,
} from '../../ui';
import { SlideInteractions } from '../questions/SlideInteractions';
import NoActive from '../questions/NoActive';
import { Module } from '../questions/Module';
import { FinishScreenExperiment } from '../finish-screen/FinishScreenExperiment';
import { PrefetchQuestionModules } from '../questions/PrefetchQuestionModules';
import { SeriesLogo } from '../questions/SeriesLogo';
import { shouldSlideShowNavigation } from '../questions/shouldSlideShowNavigation';
import { SlideInteractionsWrapper } from '../questions/SlideInteractionsWrapper';
import { useOnHasFinishedVotingOnSlide } from '../../actions/useOnHasFinishedVotingOnSlide';
import { useOnSkipQuestion } from '../../actions/useOnSkipQuestion';
import { getQuestions } from '../../selectors';
import { useNavigateToNextSlide } from './navigation/useNavigateToNextSlide';
import { ParticipateAgainNotification } from './ParticipateAgainNotification';
import { WaitForOpenModal } from './WaitForOpenModal';
import { useVoteAgain } from './useVoteAgain';

export const AudiencePaceQuestion = ({
  voteKey,
  questionIndex,
}: {
  voteKey: string;
  questionIndex: number;
}) => {
  const navigateToNextSlide = useNavigateToNextSlide();
  const questions = useAppSelector(getQuestions);
  const numberOfQuestions = questions.length;
  const question = questions[questionIndex];
  const showEndScreen = questionIndex >= numberOfQuestions;
  const voteAgainEnabled = useAppSelector(
    (state) => state.series.vote_again_enabled,
  );
  const duplicatedVote = useAppSelector(
    (state) => state.voting.failure === 'duplicated',
  );

  const progress = questionIndex / numberOfQuestions;
  const isLastSlide = questionIndex === numberOfQuestions - 1;
  const voteAgain = useVoteAgain(voteKey);

  const navigateToNextSlideUnlessPartial = useCallback(
    (_questionId: string, partial: boolean) => {
      if (partial) return;
      navigateToNextSlide();
    },
    [navigateToNextSlide],
  );
  useOnHasFinishedVotingOnSlide(navigateToNextSlideUnlessPartial);
  useOnSkipQuestion(navigateToNextSlide);

  useEffect(() => {
    // Scroll to top whenever the questions changes
    // or we transition to one of the waiting states.
    window.scrollTo(0, 0);
  }, [question?.id]);

  if (showEndScreen) return <FinishScreenExperiment />;
  if (!question) return <NoActive />;

  return (
    <>
      <PrefetchQuestionModules />
      <ProgressBar progress={progress} />
      <WaitForOpenModal question={question} />
      {duplicatedVote && voteAgainEnabled && (
        <ParticipateAgainNotification onClickStartOver={voteAgain} />
      )}

      <PageLayout footer={<Footer />}>
        <SeriesLogo />
        <Module question={question} key={question.id}>
          {(Content, Interactive) => (
            <>
              <Content />
              <Interactive />
            </>
          )}
        </Module>

        {shouldSlideShowNavigation(question.type) && (
          <NavigationButtonExperiment
            onClick={navigateToNextSlide}
            isLastSlide={isLastSlide}
          />
        )}

        <SlideInteractionsWrapper isLastSlide={isLastSlide}>
          <SlideInteractions question={question} />
          <SignUpButtonExperiment isLastSlide={isLastSlide} />
        </SlideInteractionsWrapper>
      </PageLayout>
    </>
  );
};
