import * as React from 'react';
import type { ButtonT } from '@mentimeter/ragnar-ui';
import { Box, Button, Text } from '@mentimeter/ragnar-ui';
import { useLockBodyScroll } from '../../helpers/useLockBodyScroll';
import Modal from './Modal';

interface ModalProps {
  children: React.ReactNode;

  show: boolean;
  title: string;
  onDismiss?: () => void;
}

export function InformationModal({
  show,
  onDismiss,
  title,
  children,
}: ModalProps) {
  useLockBodyScroll(show);

  return (
    <Modal show={show} onDismiss={onDismiss}>
      <Text
        as="h2"
        lineHeight="heading"
        fontSize={3}
        fontWeight="semiBold"
        color="text"
        mb={1}
      >
        {title}
      </Text>
      {children}
    </Modal>
  );
}

interface ActionContainerProps {
  children: React.ReactNode;
}

InformationModal.ActionContainer = ({ children }: ActionContainerProps) => {
  return (
    <Box
      width="100%"
      flexDirection="row"
      flexWrap="wrap"
      justifyContent="flex-end"
    >
      {children}
    </Box>
  );
};

interface ActionProps extends ButtonT {
  primary?: boolean;
}

InformationModal.Action = ({
  children,
  primary = false,
  ...props
}: ActionProps) => {
  return (
    <Button mt={2} ml={2} variant={primary ? 'primary' : undefined} {...props}>
      {children}
    </Button>
  );
};

interface DescriptionProps {
  children: React.ReactNode;
}

InformationModal.Description = ({ children }: DescriptionProps) => {
  return <Text color="textWeak">{children}</Text>;
};
