import React from 'react';
import type { AudienceQfaQuestion } from '@mentimeter/http-clients';
import { useRagnar } from '@mentimeter/ragnar-react';
import { ThumbsUpReaction } from '@mentimeter/ragnar-visuals';
import { Avatar, Badge, Box, Clickable, Text } from '@mentimeter/ragnar-ui';
import { ReactionAnimation } from '../../ui';
import { useTranslate } from '../localization/Translate';
import { useTrack } from '../../helpers/tracker';
import { getNameInitials } from '../../helpers/getNameInitials';

interface Props {
  question: AudienceQfaQuestion;
  hasUpvoted: boolean;
  onToggleUpvote: (id: number) => void;
  isUserQuestion: boolean;
  index: number;
}

function UpvoteButton({
  highlight,
  onClick,
  label,
}: {
  highlight: boolean;
  onClick: () => void;
  label: string;
}) {
  const { theme } = useRagnar();
  return (
    <ReactionAnimation
      enable={!highlight}
      vertical={false}
      themeIndex={0}
      reaction="thumbsup"
    >
      <Clickable
        py="space2"
        px="space5"
        borderRadius="full"
        borderWidth="2px"
        borderStyle="solid"
        borderColor={highlight ? 'secondary' : 'neutralWeakest'}
        bg={highlight ? 'secondaryWeak' : 'neutralWeakest'}
        onClick={onClick}
        aria-label={label}
        extend={() => ({
          cursor: 'pointer',
        })}
      >
        <ThumbsUpReaction
          size={4}
          color={
            highlight
              ? theme.colors.onSecondaryWeak
              : theme.colors.onNeutralWeakest
          }
        />
      </Clickable>
    </ReactionAnimation>
  );
}

const QfaItem = ({
  onToggleUpvote,
  hasUpvoted: upvoted,
  question: { id, question, upvotes, display_name },
  isUserQuestion,
  index,
}: Props) => {
  const trackVoter = useTrack();
  const translate = useTranslate();

  const showBadge = isUserQuestion && display_name === null;

  return (
    <Box
      key={id}
      data-testid={`qfa-item-${id}`}
      borderColor="borderWeak"
      borderStyle="solid"
      borderTopWidth={index !== 0 ? '1px' : undefined}
      flexDirection="row"
      py="space6"
      overflow="visible"
      justifyContent="space-between"
      alignItems="stretch"
      width="100%"
      gap="space4"
    >
      {display_name && (
        <Box>
          <Avatar
            type="user"
            initials={getNameInitials(display_name, 1)}
            name={display_name}
          />
        </Box>
      )}
      <Box flex="1" gap="space1">
        {display_name && (
          <Text as="p" color="textWeak">
            {display_name}
          </Text>
        )}
        {showBadge && (
          <Badge
            type="text"
            text={translate('qfa.your_question')}
            bg="infoWeak"
            color="onInfoWeak"
            textTransform="lowercase"
          />
        )}
        <Text as="p" lineHeight="150" color="text">
          {question}
        </Text>
      </Box>

      <Box justifyContent="center" alignItems="center" gap="space2">
        <UpvoteButton
          highlight={upvoted}
          label={`Upvote on ${question}`}
          onClick={() => {
            trackVoter({ event: 'Upvoted QFA' });
            onToggleUpvote(id);
          }}
        />

        <Text fontWeight="semiBold">{upvotes}</Text>
      </Box>
    </Box>
  );
};

export default QfaItem;
