import type { Extend } from '@mentimeter/ragnar-dsc';
import type { SimpleInlineNodesT } from '@mentimeter/ragnar-markdown';
import { addUnit } from '@mentimeter/ragnar-utils';
import { StringParser } from '@mentimeter/string-parser';
import autosize from 'autosize';
import * as React from 'react';
import { Box } from '../Box';
import { ErrorMessage } from '../ErrorMessage';
import FocusableCharacterCount from '../utils/_FocusableCharacterCount';
import { MarkdownMenu } from '../utils/_MarkdownMenu';
import type { ValidatableT } from '../utils/_Validators';
import { runValidators, VALIDATORS } from '../utils/_Validators';
import type { TextareaItemT } from './TextareaItem';
import { TextareaItem } from './TextareaItem';

// TODO: Unify this with Input as it's the same interaction
export interface TextareaT extends TextareaItemT, ValidatableT {
  id: string;
  autoHeight?: boolean;
  maxRows?: number | null;
  displayMarkdownMenu?: boolean | undefined;
  mdMenuOptions?: Array<SimpleInlineNodesT>;
  trackMarkdown?: (type: string) => void;
}

export const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaT>(
  (
    {
      value = '',
      defaultValue = '',
      maxLength,
      placeholder = '',
      onChange,
      validators = [],
      onValidation,
      onFocus: _of,
      onBlur: _ob,
      autoHeight = false,
      maxRows = null,
      displayMarkdownMenu = false,
      mdMenuOptions,
      trackMarkdown,
      id,
      'aria-describedby': ariaDescribedby,
      mb,
      mt,
      m,
      ml,
      mr,
      mx,
      my,
      ...rest
    },
    forwardedRef,
  ) => {
    const [currentValue, setCurrentValue] = React.useState<string>(
      String(value),
    );
    const [isFocused, setIsFocused] = React.useState<boolean>(false);
    const [hardCapped, setHardCapped] = React.useState<boolean>(false);

    // dynamically adjust maxLength so markdown "doesn't count"
    const stringParserRef = React.useRef<HTMLDivElement | null>(null);
    const [maxLengthAdjusted, setMaxLengthAdjusted] = React.useState<
      number | undefined
    >(maxLength);
    React.useEffect(() => {
      // maxLength should only need to be adjusted if md menu is enabled
      if (
        displayMarkdownMenu &&
        stringParserRef.current &&
        stringParserRef.current.innerText &&
        maxLength
      ) {
        const valueAfterParse = stringParserRef.current.innerText.trim();
        setMaxLengthAdjusted(
          maxLength + currentValue.length - valueAfterParse.length,
        );
      } else {
        setMaxLengthAdjusted(maxLength);
      }
    }, [stringParserRef, currentValue, maxLength, displayMarkdownMenu]);

    const defaultValidators = React.useMemo(
      () => [VALIDATORS.maxLength(maxLengthAdjusted as number)],
      [maxLengthAdjusted],
    );

    const validate = React.useCallback(
      (value: string) => {
        const allValidators = [...defaultValidators, ...validators];
        return runValidators(allValidators, (validators) => {
          setHardCapped(Boolean(validators['maxLength']));
          if (onValidation) onValidation(validators);
        })(value);
      },
      [onValidation, validators, defaultValidators],
    );

    React.useEffect(() => {
      setCurrentValue(String(value));
    }, [value]);

    React.useEffect(() => {
      validate(currentValue);
    }, [currentValue, validate]);

    const textareaRef = React.useRef<HTMLTextAreaElement>(null);
    React.useImperativeHandle(
      forwardedRef,
      () => textareaRef.current as HTMLTextAreaElement,
    );
    // textarea auto height init
    React.useEffect(() => {
      let textarea: HTMLTextAreaElement;
      if (
        autoHeight &&
        textareaRef &&
        textareaRef.current !== undefined &&
        textareaRef.current !== null
      ) {
        textarea = textareaRef.current;
        autosize(textarea);
      }
      return () => {
        if (!(textarea === null || textarea === undefined)) {
          autosize.destroy(textarea);
        }
      };
    }, [autoHeight]);

    // textarea auto height update
    React.useEffect(() => {
      let textarea;
      if (
        autoHeight &&
        textareaRef &&
        textareaRef.current !== undefined &&
        textareaRef.current !== null
      ) {
        textarea = textareaRef.current;
        autosize.update(textarea);
      }
    });

    // menti-style placeholders
    const handleFocus = React.useCallback(
      (focused: boolean) => {
        setIsFocused(focused);
        if (focused && defaultValue === value) {
          setCurrentValue('');
        } else {
          setCurrentValue(String(value));
        }
      },
      [setCurrentValue, defaultValue, value],
    );

    const handleUpdate = React.useMemo(
      () => (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newValue = event.target.value;
        setCurrentValue(newValue);
        if (onChange) onChange(event);
      },
      [onChange],
    );

    const extend = React.useCallback<Extend>(
      ({ theme }) => ({
        resize: autoHeight ? 'none' : 'vertical',
        maxHeight:
          autoHeight && theme.fontSizes[2] && maxRows !== null
            ? calculateMaxHeight({
                maxRows,
                lineHeight: theme.lineHeights.body,
                fontSize: addUnit(theme.fontSizes[2]),
                spacing: addUnit(theme.space[2]),
              })
            : undefined,
      }),
      [autoHeight, maxRows],
    );

    const calculateMaxHeight = ({
      maxRows,
      lineHeight,
      fontSize,
      spacing,
    }: {
      maxRows: number;
      lineHeight?: number | undefined;
      fontSize?: string | undefined;
      spacing?: string | undefined;
    }) =>
      `calc(calc(${maxRows} * (${lineHeight} * ${fontSize}) + calc(2 * ${spacing})`;

    return (
      <Box width="100%" m={m} mx={mx} my={my} mb={mb} mt={mt} ml={ml} mr={mr}>
        <FocusableCharacterCount
          id={`${id}-character-counter`}
          maxLength={maxLengthAdjusted}
          value={currentValue}
          onFocus={() => handleFocus(true)}
          onBlur={() => handleFocus(false)}
          multiline
          render={({ value, onBlur, onFocus }) => {
            return (
              <>
                <TextareaItem
                  {...rest} // this needs to be before onChange so it does not override the specific onchange prop
                  id={id}
                  aria-describedby={`${
                    ariaDescribedby ? ariaDescribedby : ''
                  } ${id}-error-message ${id}-character-counter`}
                  placeholder={String(defaultValue || placeholder)}
                  value={value}
                  onChange={handleUpdate}
                  onFocus={(e) => {
                    onFocus(e);
                    if (_of) _of(e); // <Textarea />'s onFocus
                  }}
                  onBlur={(e) => {
                    onBlur(e);
                    if (_ob) _ob(e); // <Textarea />'s onBlur
                  }}
                  maxLength={maxLengthAdjusted}
                  extend={(props) => ({
                    /** Set placeholder color if they equal */
                    ...(defaultValue === value && {
                      color: props.theme.colors.textWeak,
                    }),
                    ...extend(props),
                  })}
                  ref={textareaRef}
                />
                {/*render parsed text to be able to get actual text length (not visible to user)*/}
                <Box ref={stringParserRef} display="none">
                  <StringParser
                    source={value}
                    allowBlankLines
                    allowHyperlinks
                  />
                </Box>

                {displayMarkdownMenu && isFocused && (
                  <MarkdownMenu
                    text={value}
                    forwardedRef={textareaRef}
                    options={mdMenuOptions}
                    onUpdate={handleUpdate}
                    track={trackMarkdown}
                  />
                )}
              </>
            );
          }}
        />
        {isFocused && hardCapped && (
          <ErrorMessage id={`${id}-error-message`} mt="space2">
            You have reached the maximum length
          </ErrorMessage>
        )}
      </Box>
    );
  },
);
