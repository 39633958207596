import { getRegionByVoteKey } from '@mentimeter/region';
import { isAxiosError, voting } from '@mentimeter/http-clients';
import { identifierClient } from '../helpers/identifier';

let connectionPromise:
  | undefined
  | ReturnType<ReturnType<typeof voting>['audience']['connect']>;

export async function requestAudienceConnection(
  voteKey: string,
  isDesktopExperience: boolean,
): Promise<
  'connected' | 'audienceLimitReached' | 'desktopExperienceLimitReached'
> {
  try {
    if (!connectionPromise) {
      const region = getRegionByVoteKey(voteKey);
      connectionPromise = voting({ region }).audience.connect(
        voteKey,
        isDesktopExperience,
      );
    }
    await connectionPromise;

    return 'connected';
  } catch (error) {
    if (
      isAxiosError(error) &&
      error.response?.status === 429 &&
      error.response.data
    ) {
      // Audience or Desktop Experience limit reached
      const { code } = error.response.data as { code?: string };
      if (code === 'audience_limit_reached') {
        return 'audienceLimitReached';
      }
      if (code === 'desktop_experience_limit_reached') {
        return 'desktopExperienceLimitReached';
      }
    }
  }
  // If we couldn't make the check, allow them to connect. We shouldn't
  // block users if something goes wrong when checking.
  return 'connected';
}

export function reportAudienceDisconnected(voteKey: string) {
  const { identifier } = identifierClient.getLocalIdentifier();
  // it hasn't sense to make the disconnect request in case of the absence of an identifier
  if (!identifier) return;

  try {
    connectionPromise = undefined;
    voting().audience.sendDisconnectBeacon(voteKey, identifier);
  } catch {
    // Some browsers don't allow json in beacon requests. Measured on 2021-09-01, around
    // 1.5 % of beacon requests fail. This is an acceptable rate, and will hopefully
    // decrease as users adopt newer versions of browsers. We cannot do anything here,
    // so just swallow the error.
  }
}
