import * as React from 'react';
import { MathSvg, StringParser } from '@mentimeter/string-parser';
import type { TextT } from '@mentimeter/ragnar-ui';
import { Link as L, Text } from '@mentimeter/ragnar-ui';
import { contrast } from '@mentimeter/ragnar-colors';
import type { ThemeProps } from '@mentimeter/ragnar-react';
import { withTheme } from '@mentimeter/ragnar-react';
import { postIntegrationMessage } from '@mentimeter/integrations-shared';
import { trackEvent } from '../../helpers/tracker';
import { useIntegration } from '../../features/integrations';
import type { IntegrationContextValueT } from '../../features/integrations/index';

const renderApiUrl = globalThis.__mentimeterEnv['NEXT_PUBLIC_RENDER_API_URL'];
const wwwUrl = globalThis.__mentimeterEnv['NEXT_PUBLIC_WWW_URL'];

interface PropsT {
  source: string;
  disableLineBreaks: boolean;
  allowBlankLines?: boolean;
}
interface MathT {
  value: string;
}

interface LinkT {
  href: string;
}

const Math = ({ value }: MathT) => {
  return renderApiUrl ? (
    <MathSvg renderApiUrl={renderApiUrl} math={value} />
  ) : (
    value
  );
};

const StringParserWrapper = ({
  source,
  disableLineBreaks,
  allowBlankLines = false,
  theme,
}: PropsT & ThemeProps) => {
  const { isInternalIntegration }: IntegrationContextValueT = useIntegration();
  const BAD_CONTRAST_THRESHOLD = 2;
  const linkColor = React.useMemo(() => {
    return contrast(theme.colors.primary, theme.colors.bg) <
      BAD_CONTRAST_THRESHOLD
      ? theme.colors.textWeak
      : theme.colors.primary;
  }, [theme]);

  const Link = React.useCallback(
    ({ href, fontSize = 'inherit', ...props }: TextT & LinkT) => {
      return (
        <L
          target="_blank"
          rel="noreferrer noopener nofollow"
          href={href}
          color={linkColor}
          onClick={() => {
            trackEvent('link', 'clicked', 'Hyperlink in presentation');
            if (isInternalIntegration) {
              // For our integrations: this sends an event to the frame holding the voting web
              postIntegrationMessage(
                window?.parent,
                {
                  type: 'open-url',
                  payload: href,
                },
                wwwUrl || '*',
              );
            }
          }}
        >
          <Text {...props} fontSize={fontSize} color="none" />
        </L>
      );
    },
    [isInternalIntegration, linkColor],
  );

  const components = React.useMemo(
    () => ({
      Math,
      Link,
    }),
    [Link],
  );

  return (
    <StringParser
      components={components}
      source={source}
      disableLineBreaks={disableLineBreaks}
      allowBlankLines={allowBlankLines}
      allowHyperlinks
    />
  );
};

export default withTheme(StringParserWrapper);
