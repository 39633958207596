import { createDscFromUserColors } from '@mentimeter/ragnar-colors';
import type { UserColorsT, UserThemeT, UserFontT } from './types';

const getFontFamily = (fontKey: string): string => {
  switch (fontKey) {
    case 'gilroy':
      return 'Gilroy, sans-serif';
    case 'paul-slab':
      return 'PaulSlab, serif';
    case 'nexa-rust':
      return 'NexaRustSans, sans-serif';
    case 'geon-soft':
      return 'GeonSoft, sans-serif';
    case 'darwin-rounded':
      return 'DarwinProRd, sans-serif';
    case 'ibm-plex-mono':
      return 'IBM Plex Mono, monospace';
    case 'poppins':
      return 'Poppins, sans-serif';
    case 'playfair-display':
      return 'PlayfairDisplay, sans-serif';
    case 'catamaran':
      return 'Catamaran, sans-serif';
    case 'menti-display':
      return 'MentiDisplay, Arial, sans-serif';
    case 'menti-text':
      return 'MentiText, Arial, sans-serif';
    case 'helvetica':
      return 'Helvetica Neue, Helvetica, Arial, sans-serif';
    case 'arial':
      return 'Arial, sans-serif';
    default:
      return '';
  }
};

const getFontPair = (fontKey: string) => {
  switch (fontKey) {
    case 'gilroy':
    case 'paul-slab':
    case 'geon-soft':
    case 'darwin-rounded':
    case 'ibm-plex-mono':
    case 'poppins':
    case 'playfair-display':
    case 'catamaran':
    case 'helvetica':
    case 'arial':
      return {
        heading: getFontFamily(fontKey),
        body: getFontFamily(fontKey),
        monospace: 'monospace',
      };
    case 'nexa-rust':
      return {
        heading: getFontFamily(fontKey),
        body: getFontFamily('gilroy'),
        monospace: 'monospace',
      };
    case 'menti':
      return {
        heading: getFontFamily('menti-display'),
        body: getFontFamily('menti-text'),
        monospace: 'monospace',
      };
    default:
      return {
        heading: getFontFamily('menti-display'),
        body: getFontFamily('menti-text'),
        monospace: 'monospace',
      };
  }
};

export function createUserTheme({
  userColors,
  font,
  backdropAlpha,
}: {
  userColors: UserColorsT;
  font?: UserFontT | undefined;
  backdropAlpha?: number | undefined;
}): UserThemeT {
  return {
    ...createDscFromUserColors(userColors),
    fonts: { inherit: 'inherit', ...getFontPair(font?.key ?? '') },
    backdropAlpha: typeof backdropAlpha === 'number' ? backdropAlpha : 0.7,
  };
}
