import * as React from 'react';
import { Backdrop, Portal, PortalHelper } from '@mentimeter/ragnar-ui';
import { useTransition, animated } from 'react-spring';
import { useRagnar } from '@mentimeter/ragnar-react';

interface ActionsheetT {
  children: React.ReactNode;
  show: boolean;
  onDismiss?: () => void;
}

export const PORTAL_ID = 'actionsheet-portal-absolute';

export const ActionsheetAbsolute = ({
  show,
  onDismiss,
  children,
}: ActionsheetT) => {
  const { theme } = useRagnar();
  const { modal: zIndex } = theme.zIndex;
  const transition = useTransition(show, {
    from: { y: 100, opacity: 0, backdropOpacity: 0 },
    enter: { y: 0, opacity: 1, backdropOpacity: 1 },
    leave: { y: 100, opacity: 0, backdropOpacity: 0 },
  });

  return (
    <Portal domId={PORTAL_ID}>
      {transition(
        (styles, isShowing) =>
          isShowing && (
            <PortalHelper
              zIndex={zIndex}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'flex-end',
                // When the action-sheet is closed, we immediately want to be able to click things behind it
                pointerEvents: show ? 'auto' : 'none',
                position: 'absolute',
                height: '100%',
                width: '100%',
                overflow: 'hidden',
              }}
            >
              <animated.div
                role="dialog"
                aria-modal="true"
                aria-live="polite"
                style={{
                  position: 'absolute',
                  zIndex: 2,
                  bottom: 0,
                  width: '100%',
                  padding: '0 16px',
                  maxHeight: '100%',
                  transform: styles.y.to((y: any) => `translate3d(0,${y}%,0)`),
                  opacity: styles.opacity,
                }}
              >
                {children}
              </animated.div>
              <Backdrop
                as={animated.div}
                onClick={onDismiss}
                style={{
                  opacity: styles.backdropOpacity as unknown as number,
                  position: 'absolute',
                }}
              />
            </PortalHelper>
          ),
      )}
    </Portal>
  );
};
