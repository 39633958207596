import type { Question } from '@mentimeter/http-clients';
import React, { useCallback } from 'react';
import { Box } from '@mentimeter/ragnar-ui';
import { PORTAL_ID_VOTING_PANEL_BOTTOM_BAR } from '@mentimeter/voting-ui';
import { useAppDispatch, useAppSelector } from '../../redux-hooks';
import {
  Footer,
  NavigationButtonExperiment,
  PageLayout,
  ProgressBar,
  SignUpButtonExperiment,
} from '../../ui';
import { usePresentationState } from '../../presentation-state';
import { useTrack } from '../../helpers/tracker';
import { onFollowPresenter } from '../../reducers/votingSlice';
import { SlideInteractions } from '../questions/SlideInteractions';
import { Module } from '../questions/Module';
import { FinishScreenExperiment } from '../finish-screen/FinishScreenExperiment';
import { SeriesLogo } from '../questions/SeriesLogo';
import { CloseVotingNotification } from '../questions/CloseVotingNotification';
import { shouldSlideShowNavigation } from '../questions/shouldSlideShowNavigation';
import { SlideInteractionsWrapper } from '../questions/SlideInteractionsWrapper';
import { getDSCWithTheme } from '../../selectors';
import { InteractionObserver } from '../InteractionObserver';
import { Loading } from './Loading';
import WaitForActive from './WaitForActive';
import HasVotedOnQuestion from './HasVotedOnQuestion';
import { ChangeSlideNotification } from './ChangeSlideNotification';

const Content = ({
  question,
  hasVotedOnCurrentQuestion,
  waitForNextSlide,
  pictogramColor,
}: {
  question: Question;
  hasVotedOnCurrentQuestion: boolean;
  waitForNextSlide: boolean;
  pictogramColor: string | undefined;
}) => {
  const { setSlideHasBeenInteractedWith } = usePresentationState();
  const handleSlideInteraction = useCallback(
    () => setSlideHasBeenInteractedWith(question.public_key),
    [question.public_key, setSlideHasBeenInteractedWith],
  );

  if (hasVotedOnCurrentQuestion)
    return <HasVotedOnQuestion pictogramColor={pictogramColor} />;
  if (waitForNextSlide)
    return <WaitForActive pictogramColor={pictogramColor} />;

  const isContentSlide = question.type === 'slide';
  const isVotingOpen = isContentSlide || question.active;

  return (
    <>
      <SeriesLogo />

      {!isVotingOpen && <CloseVotingNotification />}

      <InteractionObserver onInteraction={handleSlideInteraction}>
        <Module key={question.id} question={question}>
          {(Content, Interactive) => (
            <>
              <Content />
              <Interactive />
            </>
          )}
        </Module>
      </InteractionObserver>
    </>
  );
};

export const PresenterPaceQuestion = ({ question }: { question: Question }) => {
  const {
    hasVotedOnCurrentQuestion,
    waitForNextSlide,
    showEndScreen,
    isOutOfSync,
    isLoading,
    followPresenter,
    goToSuccessPage,
    hasNextSlide,
    progress,
  } = usePresentationState();

  const trackVoter = useTrack();
  const dispatch = useAppDispatch();
  const showFinishButton =
    !hasNextSlide && shouldSlideShowNavigation(question.type);
  const theme = useAppSelector(getDSCWithTheme);
  const pictogramColor = theme.pictogramColor;

  if (isLoading) return <Loading />;
  if (showEndScreen) return <FinishScreenExperiment />;

  return (
    <>
      <ProgressBar progress={progress} />
      <Box width="100%" alignItems="center">
        {isOutOfSync && (
          <ChangeSlideNotification
            onClick={() => {
              trackVoter({
                event: 'Clicked go to slide',
                properties: { 'question type': question.type },
              });
              dispatch(onFollowPresenter());
              followPresenter();
            }}
          />
        )}
      </Box>
      <PageLayout footer={<Footer />}>
        <Content
          question={question}
          hasVotedOnCurrentQuestion={hasVotedOnCurrentQuestion}
          waitForNextSlide={waitForNextSlide}
          pictogramColor={pictogramColor}
        />

        {showFinishButton && (
          <NavigationButtonExperiment
            onClick={goToSuccessPage}
            isLastSlide={showFinishButton}
          />
        )}

        <SlideInteractionsWrapper isLastSlide={showFinishButton}>
          <SlideInteractions question={question} />
          <SignUpButtonExperiment isLastSlide={showFinishButton} />
        </SlideInteractionsWrapper>
      </PageLayout>

      <Box
        width="100%"
        position="fixed"
        bottom={0}
        zIndex={1}
        id={PORTAL_ID_VOTING_PANEL_BOTTOM_BAR}
      />
    </>
  );
};
