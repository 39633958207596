import React from 'react';
import { Box } from '@mentimeter/ragnar-ui';
import { useFinishScreenExperiment } from '../../ui';

export const SlideInteractionsWrapper = ({
  children,
  isLastSlide,
}: { children: React.ReactNode; isLastSlide?: boolean | undefined }) => {
  const isFinishScreenExperiment = useFinishScreenExperiment();
  const showSignUpButton = isFinishScreenExperiment && isLastSlide;

  if (showSignUpButton) {
    return (
      <Box width="100%" alignContent="center" gap="space2">
        {children}
      </Box>
    );
  }

  return (
    <Box
      width="100%"
      alignContent="center"
      justifyContent="end"
      mt="space4"
      flex={1}
      gap="space2"
    >
      {children}
    </Box>
  );
};
