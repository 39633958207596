import type { ReactNode } from 'react';
import * as React from 'react';
import { RagnarMarkdown } from '@mentimeter/ragnar-markdown';
import type { KosmosFontSize, KosmosSpacing } from '@mentimeter/ragnar-dsc';
import { Text } from '../Text';
import { List } from '../List';
import { MarkdownLink } from './MarkdownLink';

type AllowedTypes =
  | 'text'
  | 'paragraph'
  | 'heading'
  | 'strong'
  | 'emphasis'
  | 'list'
  | 'listItem'
  | 'thematicBreak'
  | 'link'
  | 'image';

export interface MarkdownT {
  children: string;
  allowedTypes?: Array<AllowedTypes>;
}

const BLOCK_SPACING: KosmosSpacing = 'space2';
const HEADING_SPACING: KosmosSpacing = 'space4';
const MIN_HEADING_LEVEL = 2;
const MAX_HEADING_LEVEL = 4;

const HEADING_FONT_SIZES: Record<number, KosmosFontSize> = {
  2: '125',
  3: '112.5',
  4: '100',
};

export const MarkdownText = ({ children }: { children: ReactNode }) => children;

export const MarkdownParagraph = ({ children }: { children: ReactNode }) => (
  <Text
    as="p"
    mb={BLOCK_SPACING}
    color="inherit"
    fontSize="inherit"
    extend={() => ({
      ':last-child': {
        marginBottom: 0,
      },
    })}
  >
    {children}
  </Text>
);

export const MarkdownHeading = ({
  children,
  level,
}: {
  level: number;
  children: ReactNode;
}) => {
  // We increase the level by one to start at an h2 since most pages should have their own h1 already.
  const HEADING_LEVEL = clamp(level + 1, MIN_HEADING_LEVEL, MAX_HEADING_LEVEL);

  return (
    <Text
      as={`h${HEADING_LEVEL}`}
      fontSize={HEADING_FONT_SIZES[HEADING_LEVEL]}
      mt={HEADING_SPACING}
      mb={BLOCK_SPACING}
      color="inherit"
      extend={() => ({
        ':last-child': {
          marginBottom: 0,
        },
      })}
    >
      {children}
    </Text>
  );
};

export const MarkdownStrong = ({ children }: { children: ReactNode }) => (
  <Text as="span" color="inherit" fontSize="inherit" fontWeight="semiBold">
    {children}
  </Text>
);

export const MarkdownEmphasis = ({ children }: { children: ReactNode }) => (
  <Text as="span" color="inherit" fontSize="inherit" fontStyle="italic">
    {children}
  </Text>
);

export const MarkdownList = ({
  ordered,
  children,
}: {
  ordered: boolean;
  children: ReactNode;
}) => (
  <List
    ml="space8"
    mb={BLOCK_SPACING}
    as={ordered ? 'ol' : 'ul'}
    extend={() => ({
      ':last-child': {
        marginBottom: 0,
      },
    })}
  >
    {children}
  </List>
);

export const MarkdownListItem = ({ children }: { children: ReactNode }) => (
  <Text as="li" color="inherit" fontSize="inherit">
    {children}
  </Text>
);

const RENDERERS = {
  text: MarkdownText,
  paragraph: MarkdownParagraph,
  heading: MarkdownHeading,
  strong: MarkdownStrong,
  emphasis: MarkdownEmphasis,
  list: MarkdownList,
  listItem: MarkdownListItem,
  link: MarkdownLink,
};

/**
 * ## Markdown
 *
 * NB! Javascript links are not allowed anymore.
 *
 * @param {string} children the string to be formatted
 * @param {AllowedTypes} allowedTypes all types are allowed on default. To restrict amount of types, set them manually. The types are: text, paragraph, heading, strong, emphasis, list, listItem, thematicBreak, link
 *
 * ### More on `react-markdown` security from [their docs](https://github.com/remarkjs/react-markdown#uritransformer)
 * `uriTransformer` is the default URL transform, which you can overwrite. It’s given a URL and cleans it, by allowing only http:, https:, mailto:, and tel: URLs, absolute paths (/example.png), and hashes (#some-place).
 */

export const Markdown = ({
  children,
  allowedTypes = [
    'text',
    'paragraph',
    'heading',
    'strong',
    'emphasis',
    'list',
    'listItem',
    'thematicBreak',
    'link',
  ],
}: MarkdownT) => (
  <RagnarMarkdown
    renderers={RENDERERS}
    source={children}
    allowedTypes={allowedTypes}
    unwrapDisallowed
  />
);

function clamp(num: number, min: number, max: number) {
  return Math.min(Math.max(num, min), max);
}
