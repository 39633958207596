import { useCallback } from 'react';
import { Box, Text } from '@mentimeter/ragnar-ui';
import type { Question } from '@mentimeter/http-clients';
import { QuestionImage } from '@mentimeter/question-modules-shared';
import { useTrack } from '../../helpers/tracker';
import { usePresentationState } from '../../presentation-state';
import { useTranslate } from '../localization/Translate';
import { FinishScreenExperimentContent } from '../finish-screen/FinishScreenExperiment';
import HasVotedOnQuestion from '../presenter-pace/HasVotedOnQuestion';
import WaitForActive from '../presenter-pace/WaitForActive';
import { CloseVotingNotification } from '../questions/CloseVotingNotification';
import { Module } from '../questions/Module';
import {
  Footer,
  NavigationButtonExperiment,
  SignUpButtonExperiment,
} from '../../ui';
import { SlideInteractions } from '../questions/SlideInteractions';
import { useAppDispatch, useAppSelector } from '../../redux-hooks';
import { getDSCWithTheme } from '../../selectors';
import { Cfa } from '../cfa/Cfa';
import { Qfa } from '../qfa/Qfa';
import { ChangeSlideNotification } from '../presenter-pace/ChangeSlideNotification';
import { onFollowPresenter } from '../../reducers/votingSlice';
import { InteractionObserver } from '../InteractionObserver';
import { shouldSlideShowNavigation } from '../questions/shouldSlideShowNavigation';
import { SlideInteractionsWrapper } from '../questions/SlideInteractionsWrapper';
import PresentationInProgress from './PresentationInProgress';

const Content = ({
  question,
  hasVotedOnCurrentQuestion,
  waitForNextSlide,
  isContentSlide,
}: {
  question: Question;
  hasVotedOnCurrentQuestion: boolean;
  waitForNextSlide: boolean;
  isContentSlide: boolean;
}) => {
  const theme = useAppSelector(getDSCWithTheme);
  const translate = useTranslate();
  const { setSlideHasBeenInteractedWith } = usePresentationState();
  const handleSlideInteraction = useCallback(
    () => setSlideHasBeenInteractedWith(question.public_key),
    [question.public_key, setSlideHasBeenInteractedWith],
  );
  const showParticipantImage = question.layout?.type === 'default';

  if (hasVotedOnCurrentQuestion)
    return <HasVotedOnQuestion pictogramColor={theme.pictogramColor} />;
  if (waitForNextSlide)
    return <WaitForActive pictogramColor={theme.pictogramColor} />;
  if (isContentSlide) {
    return (
      <>
        {showParticipantImage && (
          <QuestionImage src={question.image} translateFn={translate} mb={3} />
        )}

        <PresentationInProgress pictogramColor={theme.colors.disabledStrong} />
      </>
    );
  }

  return (
    <>
      {!question.active && <CloseVotingNotification />}

      {showParticipantImage && (
        <QuestionImage src={question.image} translateFn={translate} mb={3} />
      )}

      {question.question_description && (
        <Box width="100%" pb="space6">
          <Text color="text">{question.question_description}</Text>
        </Box>
      )}

      <InteractionObserver onInteraction={handleSlideInteraction}>
        <Module key={question.id} question={question}>
          {(_, Interactive) => <Interactive />}
        </Module>
      </InteractionObserver>
    </>
  );
};

const EndScreen = () => (
  <Box width="100%" flex="1 0 auto" alignItems="center">
    <FinishScreenExperimentContent qfa={<Qfa isFinishScreen absolute />} />
  </Box>
);

export const VotingPanel = ({ question }: { question: Question }) => {
  const {
    hasVotedOnCurrentQuestion,
    waitForNextSlide,
    showEndScreen,
    goToSuccessPage,
    hasNextSlide,
  } = usePresentationState();
  const showFinishButton =
    !hasNextSlide && shouldSlideShowNavigation(question.type);
  const { isOutOfSync, followPresenter } = usePresentationState();

  const trackVoter = useTrack();
  const dispatch = useAppDispatch();
  const isContentSlide = ['slide', 'qfa'].includes(question.type);

  if (showEndScreen) return <EndScreen />;

  return (
    <>
      <Box width="100%" flex="1 0 auto" alignItems="center">
        <Box width="100%" mt="space20" />
        {isOutOfSync && (
          <ChangeSlideNotification
            onClick={() => {
              trackVoter({
                event: 'Clicked go to slide',
                properties: { 'question type': question.type },
              });
              dispatch(onFollowPresenter());
              followPresenter();
            }}
          />
        )}
        <Content
          question={question}
          hasVotedOnCurrentQuestion={hasVotedOnCurrentQuestion}
          waitForNextSlide={waitForNextSlide}
          isContentSlide={isContentSlide}
        />

        {showFinishButton && (
          <NavigationButtonExperiment
            onClick={goToSuccessPage}
            isLastSlide={showFinishButton}
          />
        )}
        <SlideInteractionsWrapper isLastSlide={showFinishButton}>
          <SlideInteractions
            question={question}
            cfa={<Cfa absolute activePublicKey={question.public_key} />}
            qfa={
              <Qfa
                absolute
                activePublicKey={question.public_key}
                moduleId={question.module_id}
              />
            }
          />
          <SignUpButtonExperiment isLastSlide={showFinishButton} />
        </SlideInteractionsWrapper>
      </Box>
      <Box justifyContent="center" width="100%" overflow="hidden">
        <Footer />
      </Box>
    </>
  );
};
